@font-face {
  font-family: Helvetica;
  src: url("../../fonts/Helvetica\ Neue\ Medium\ Extended.otf");
}

.footer {
  width: 95%;
  min-height: max-content;
  max-height: min-content;
  margin: 1em auto;
  border-radius: 2em;
  background-color: #4f486a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden;
  font-family: Helvetica;
  border-bottom: calc(1.5 * 2em) solid #d295c2;
}

.Container {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 2em;
}

.List {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  margin: 0 0.5em;
}

.List h1,
.List h6 {
  color: white;
  margin: 0 0 1em 0;
}

.List p,
.List a {
  color: #9f9f9f;
  font-size: 0.9em;
}

.List p,
a {
  color: #9f9f9f;
  font-size: 0.9em;
}

.List a {
  text-decoration: none;
  color: white;
  font-size: 0.7em;
}

.Icons,
.MenuPages {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.MenuPages {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.MenuPages a {
  margin: 0.5em 0;
}

.Icons div a svg {
  color: white;
  font-size: 1.5em;
  margin: 0 0.5em;
}

.form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form input {
  background-color: white;
  color: black;
  border-radius: 0.1em;
  outline: none;
  width: 90%;
  border: none;
  font-size: 0.8em;
  padding: 0.5em;
}

.form a {
  height: 100%;
  border: 1px solid grey;
  border-radius: 0.1em;
  background-color: transparent;
}

.form a svg {
  color: white;
  margin: 0.4em;
  font-size: 1.5em;
}

@media (max-width: 800px) {
  .Container {
    flex-direction: column;
  }

  .List {
    margin: 0.5em 0;
  }
}
