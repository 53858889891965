.blogs_page {
  margin-top: 5vh;
}
/* blogs main  */
.blogs-heading {
  width: 80%;
  margin: auto;
}
.blogs-head {
  color: #ffb281;
  text-align: center;
  font: normal bold 85px/102px Helvetica;
}

.solo_blog_image {
  object-fit: fill;
  max-height: 350px;
  height: 250px;
  /* width: 100%; */
  border-radius: 20px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.blogs-main {
  display: flex;
  flex-direction: row;
  height: calc(10rem * 5);
  align-items: center;
  justify-content: center;
}
.right-blogs {
  margin-left: 2em;
  border-left: 3px solid grey;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  overflow-y: scroll;
}

/* trending blogs  */
.trending-posts-heading {
  margin: auto;
}
.trending-posts {
  color: #ffb281;
  text-align: center;
  font: normal bold 3.5rem Helvetica;
  margin: 1em 0;
}
.trending {
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 40px;
  margin-bottom: 40px; */
  width: 100%;
  height: 100%;
  display: grid;
  margin-left: 2px;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
.trendingFit {
  object-fit: cover;
  max-height: 300px;
  min-height: 200px;
  height: 300px;
  border-radius: 10px;
}
.trendingCardBody {
  padding: 2px;
  /* margin-top: ; */
  overflow: scroll;
  color: grey;
  word-wrap: break-word;
}
.trend_card {
  background-color: #242632;
  border-bottom: 20px solid #8c89c8;
  border-radius: 40px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.Recentcard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.card-body {
  padding: 0 !important;
}

.card-title {
  color: white;
  margin-bottom: 0.5rem;
  padding: 5px 0 5px 5px;
}
.card-text {
  color: white;
  max-width: 82ch;
  padding-left: 5px;
}
.card-btn {
  float: right;
  align-self: flex-end;
  margin: 0.5em;
}

/* recent blogs  */
.wrapper-container-recent-blogs {
  /* display: grid;
    grid-auto-flow: column;
    scroll-behavior: smooth;
    overflow-y: auto;
    cursor: grab;
    overscroll-behavior-x:contain ;
    scroll-snap-type: x mandatory;
    scrollbar-width: none; */
  width: 100%;
  height: 100%;
  display: grid;
  /* gap: 1em; */
  grid-template-columns: repeat(auto-fit, minmax(calc(7em * 3), 1fr));
}
::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.SectionFirst {
  height: 100%;
  width: 100%;
}

.blog_card {
  height: fit-content;
  /* min-width: calc(100px * 4); */
  border-radius: 12px;
  margin-bottom: 30px;
  background-color: #242632;
  margin-right: 10px;
  min-height: -webkit-fill-available;
  border-bottom: 10px solid #8c89c8;
}
.purp-hr {
  color: #8668f2;
  position: relative;
  top: 20px;
  border: none;
  height: 12px !important;
  width: 80%;
  margin: auto;
  margin-bottom: 50px;
}
.recent-blogs-heading {
  margin-bottom: 50px;
  text-align: center;
}
.recent-blogs {
  color: #ffb281;
  font: normal bold 3.5rem/4.1875rem Helvetica;
}
/* Recent Card New Changes */
.fitImage {
  object-fit: cover;
  max-height: 155px;
  width: 100%;
  border-radius: 5px;
}

.head {
  margin-top: 3px;
}

.cardDescription {
  color: grey;
  max-height: 105px;
  word-wrap: break-word;
  width: 26ch;
}
/* Recent Card new Changes Ends here */

.right-blog-image {
  object-fit: cover;
  height: 40%;
  max-height: 200px;
}
.gridGap{
  grid-gap: 40px;
}
@media screen and (max-width: 900px) {
  .trend_card {
    margin-bottom: 10px;
  }
  .blogs-main {
    flex-direction: column;
    height: 100%;
  }
  .right-blogs {
    margin-top: 1em;
    margin: 2em auto;
    border-left: 0;
    padding-left: 0;
  }

  .blog_card {
    width: 100%;
  }
}

@media screen and (max-width: 849px) {
  .blogs-head,
  .trending-posts {
    width: 100%;
  }
  .trend_card {
    margin-bottom: 10px;
  }

  /* #headings{
        font-size: 5rem;
    } */

  
  .blogs-head,
  .trending-posts {
    font-size: 3em;
    font-weight: bolder;
    margin: 0.5em 0;
  }
}

/* Media Qeueries below 800px for Flex Column for Recent blogs  to be added*/
/* @media screen and (max-width:850px) {
    .rowCSS{
        flex-direction: column !important;
        justify-content: center;
        overflow-y: scroll;
    }

} */

@media screen and (min-width: 950px) {
  .trend_card {
    width: 90%;
  }
}

@media (max-width: 900px) {
  .solo_blog {
    width: 100%;
  }
}
