@font-face {
  font-family: Helvetica;
  src: url("../../../fonts/Helvetica\ Neue\ Medium\ Extended.otf");
}
.list_title {
  font: normal normal bold 90px Helvetica;
  color: #8668f2;
  font-size: 4em;
  margin: 1em 0;
}
.list_description {
  font-size: 20px;
  font: normal normal medium 20px/30px Poppins;
  /* width: 28.8%; */
  margin-top: 20px;
  color: white;
  margin-bottom: 63px;
}
.note-list {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(450px, 2fr));
  margin: auto;
}

.ContainerSelectYr {
  width: 80%;
  margin: 1em auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media (min-width: 218px) and (max-width: 468px) {
  .note-list {
    grid-template-columns: repeat(auto-fit, minmax(90%, 2fr));
  }
  .list_title {
    font-size: 2em;
  }
}

/* @media (min-width:467px) and (max-width:768px){
    
}
  
  @media (min-width:769px) and (max-width:918px) {
    
  }
   */
@media (min-width: 918px) and (max-width: 1025px) {
  .note-list {
    grid-template-columns: repeat(auto-fit, minmax(390px, 2fr));
  }
}
