.card_container {
  border: 7px solid #8668f2;
  border-radius: 30px;
  width: 414px;
  min-height: 600px;
  height: fit-content;
  margin: auto;
  margin-bottom: 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
}

.Card_Circle {
  width: 30em;
  height: 30em;
  border-radius: 50%;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
  z-index: -1;
  position: absolute;
  background-color: #8668f2;
}

.Card_Image {
  width: 100%;
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin: 1em 0 0 0;
}

.Card_ImageContainer {
  width: 15em;
  height: 15em;
  background-color: rgb(57, 57, 57);
  transition: 0.5s;
  border: 1em solid #28333c;
}

.Card_InnerImgeContainer {
  width: 100%;
  height: 100%;
  transition: 0.5s;
}

.Card_ImageContainer,
.Card_InnerImgeContainer {
  overflow: hidden;
  object-fit: cover;
  border-radius: 50%;
}

.Card_ImageContainer img {
  width: 100%;
  height: 100%;
}

.Card_name {
  color: white;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
}

.Card_Board,
.Card_Interest {
  font-size: 1.1em;
  color: grey;
}

.Card_Detail {
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.Card_quote {
  color: white;
}

.Card_Interest,
.Card_quote {
  text-align: center;
}

.description {
  height: 46px;
  margin-left: 9.5%;
  margin-top: 12px;
  margin-bottom: 21.5px;
}

.card_container:hover .Card_Social {
  transform: translateY(0);
}

.card_container:hover .Card_InnerImgeContainer {
  transform: scale(0.8);
}

.card_container:hover .Card_ImageContainer {
  border: 1em solid #7552f3;
}

.card_container:hover .Card_Circle {
  transform: translate(-50%, -50%);
}

.Card_Social,
.Card_Circle {
  transition: 300ms;
}

.Card_Social {
  width: 100%;
  background-color: #8668f2;
  transform: translateY(10em);
}

.Card_SocialContainer {
  width: 60%;
  margin: 0 auto;
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Card_SocialContainer a {
  text-decoration: none;
  color: black;
  font-size: 1.3em;
}
.log {
  height: 27px;
  margin-left: 9.5%;
  margin-bottom: 8px;
}
@media (max-width: 800px) {
  .Card_Social {
    transform: translate(0);
  }
}
@media (min-width: 218px) and (max-width: 468px) {
  .card_container {
    width: 85%;
  }
}
@media (min-width: 918px) and (max-width: 1023px) {
  * {
    overflow-x: hidden;
  }
}
