.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: #242834;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 200ms;
  z-index: 99999999999;
}

.Outsider {
  display: none;
  position: fixed;
  top: 5%;
  left: 5%;
  color: white;
  font-size: 1.5em;
  cursor: pointer;
  z-index: 9999999999;
}

.grow {
  width: 20%;
}

.shrink {
  width: 5%;
}

.close {
  display: none;
}

.opener {
  display: block;
}

.bars {
  font-size: 1.5em;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 0.2em 0;
}

.Align {
  height: 95%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.sided {
  justify-content: flex-end !important;
}

.top_section {
  padding: 0 0.5em;
}

.hide {
  display: none !important;
}

.showNow {
  display: block !important;
}

.routes {
  height: 95%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: 0.5em 0 0 0;
}

.des {
  color: white;
  text-align: center;
  width: auto;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.des img {
  width: 50%;
  height: 100%;
}

.link {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  color: grey;
  gap: 0.5em;
  text-decoration: none;
  padding: 0.5em;
  font-size: 0.95em;
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid #242834;
}

.linkClosed {
  justify-content: center !important;
}

.link:hover {
  color: white;
}

.link:nth-child(3n + 0) {
  pointer-events: none;
}

.active {
  border-left: 0.3em solid #8668f2;
  color: white;
  background: linear-gradient(
    90deg,
    rgba(250, 250, 250, 0.10454604204963236) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

@media (max-width: 800px) {
  .sidebar {
    transform: translateX(-100%);
  }
  .shrink,
  .grow {
    width: 50% !important;
  }
  .grow {
    transform: translateX(0%);
  }
  .hide {
    display: block !important;
  }
  .showNow {
    display: none !important;
  }
  .close {
    display: block;
  }
  .opener {
    display: none !important;
  }
  .Outsider {
    display: block !important;
  }
}
