/* loader */
.container_loader {
  /* z-index: 2;
  width: 100vw;
  height: 100vh;
  position: relative;
  left: 50%;
  top: 50%;
  margin-top: 300px;
  transform: translate(-50%, -50%); */
  height: 50vh;
  margin-top: 20vh;
}
.circle_loader {
  width: 40px;
  height: 40px;
  position: absolute;
  border-radius: 50%;
  background-color: #dfb79e;
  left: 15%;
  transform-origin: 50%;
  animation: circle 0.5s alternate infinite ease;
}

@keyframes circle {
  0% {
    top: 100px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }
  30% {
    height: 25px;
    border-radius: 50%;
    transform: scaleX(1.7);
  }
  100% {
    top: 0%;
  }
}

.circle_loader:nth-child(2) {
  left: 50%;
  animation-delay: 0.2s;
}
.circle_loader:nth-child(3) {
  left: 85%;
  right: 15%;
  animation-delay: 0.3s;
}

.shadow_loader {
  width: 40px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(220, 91, 81, 0.5);
  position: absolute;
  top: 100px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow 0.5s alternate infinite ease;
}

@keyframes shadow {
  0% {
    transform: scaleX(1.5);
  }
  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }
  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
}

.shadow_loader:nth-child(4) {
  left: 50%;
  animation-delay: 0.2s;
}
.shadow_loader:nth-child(5) {
  left: 85%;
  right: 15%;
  animation-delay: 0.3s;
}
