body {
  margin: 0%;
  padding: 0%;
  min-width: 100vw;
  min-height: 100vh;
  background: transparent
    linear-gradient(241deg, #263138 0%, #29343f 54%, #1a1c1f 100%) 0% 0%
    no-repeat padding-box;
}
@font-face {
  font-family: Helvetica;
  src: url("../../fonts/Helvetica\ Neue\ Medium\ Extended.otf");
}
@font-face {
  font-family: HelveticaBold;
  src: url(../../fonts/Helvetica\ Neue\ LTW0697BlkCnObl.otf);
}
.arrow_back {
  position: absolute;
  top: 8%;
  left: 8%;
  font-size: 2rem;
}
.arrow_back:hover {
  cursor: pointer;
}
.login {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  /* flex-wrap: wrap; */
  /* text-align: center; */
  color: white;
}
.card-body_login {
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em;
}

.Login_Container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
@media (max-width: 1500px) {
  .login_form {
    transform: scaleX(0.8);
  }
  .login_card {
    /* transform: scale(0.8); */
  }
}
@media (max-width: 1200px) {
  body {
    min-height: 100vh;
  }
  .login_form {
    /* transform: scale(110%);
    margin: auto; */
    /* margin-left: 7%; */
    /* left: 45%; */
  }
}
@media (max-width: 1000px) {
}
@media (max-width: 825px) {
  .login_card {
  }
}
@media (max-width: 600px) {
  .login_card {
  }
}
@media (max-width: 768px) {
  .arrow_back {
    top: 2% !important;
    right: 2% !important;
    left: auto;
  }
  .Login_Container {
    flex-direction: column;
  }
  .login_form {
    width: 100% !important;
    margin-top: 5em;
  }
  .login {
    flex-direction: column;
  }
  .login_card {
    max-height: none !important;
    min-height: none !important;
  }
}
@media (max-width: 530px) {
  .login_form {
    /* transform: scale(60%);
    margin-left: -22%;
    top: 20%; */
  }
  .welcome {
    font-size: 2.5rem !important;
  }
  .login_card {
  }
}
@media (max-width: 500px) {
  .login_card {
  }
  .welcome {
    font-size: 1.7rem;
  }
  .website_btn {
  }
}
@media (max-width: 400px) {
  .login_form {
    /* transform: scale(55%);
    margin-left: -32%;
    top: 15%;
    margin-top: -15%; */
  }
  .login_card {
    margin: 0 5em !important;
    width: 80%;
    transform: scale(0.8);
  }
  .input_form input {
    /* width: 130%; */
    font-size: 0.8em !important;
  }
}
@media (max-width: 350px) {
  .login_form {
    /* transform: scale(51%);
    margin-left: -38%;
    margin-top: -20%;
    min-width: 400px; */
  }
}
.login_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* position: absolute; */
  z-index: 3;
  top: 30%;
  left: 25%;
  width: 50%;
  /* width: 30%; */
}
.heading {
  font-size: 3.6rem;
  text-align: center;
  font: normal normal bold 49px/59px Helvetica;
}
@media screen and (max-width: 322px) {
  .heading {
    font-size: 2.6rem;
  }
}
.error_login {
  margin-top: -5%;
  color: red;
}

.form_part {
  width: 100%;
  padding-top: 10%;
  margin-left: -35%;
}

.input_form {
  padding-top: 3%;
  padding-bottom: 3%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 30%; */
  /* gap: 10px; */
  padding-left: 5%;
  padding-right: 2%;
  background-color: #696d73;
  border: 1px solid #ffffff;
  border-radius: 20px;
  opacity: 1;
  margin-bottom: 10%;
  /* width: 140%; */
  color: #ffffff;
}

.Main_Login {
  width: 100%;
}

.input_form input {
  margin-left: 1em;
  background-color: transparent !important;
}

@media screen and (max-width: 320px) {
  .input_form {
    width: 130%;
    font-size: 0.3em !important;
  }
}

.logo {
  width: 26px;
  height: 26px;
}
.input {
  /* border: white 1px solid; */
  border: none;
  width: 140%;
  background-color: #696d73;
  align-self: center;
  color: #ffffff;
  height: 120%;
  font: normal normal medium Helvetica;
  letter-spacing: 1.7px;
  font-size: 1.1rem;
}
.input:focus {
  outline: none !important;
  border: none;
}
input::placeholder {
  color: #ffffff;
}
.lower_login {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.forgot {
  color: #8790a2;
  cursor: pointer;
  /* width: 700px; */
}
.forgot:hover {
  color: #8668f2;
}
.login_btn {
  background-color: #8668f2;
  height: 50px;
  border-radius: 20px;
  text-align: center;

  padding: 0% 10%;
  /* padding-bottom: -1%; */
  /* position: absolute; */
  /* right: -15%; */
  /* margin-left: 20%; */
}
.login_btn button {
  width: 100%;
  height: 100%;
  border: none;
}
button:active {
  width: 100%;
  height: 100%;
  border: 0px;
  outline: none;
}
.login_btn:hover {
  background-color: #ffb281;
}
.btn_txt {
  font-size: 1.2rem;
  color: #ffffff;
  letter-spacing: 0px;
  padding-top: 9%;
  text-align: center;
  font: normal normal bold 20px/24px Helvetica;
}

.login_card {
  background-image: url("https://res.cloudinary.com/dcglxmssd/image/upload/v1679454730/iPhone_13_mini_-_1_wkjfxq.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  /* top: 76%; */
  height: 100%;
  max-height: 90vh;
  min-height: 90vh;
  width: fit-content;
  background-color: #4f486a;
  border: 7px solid #8668f2;
  border-radius: 60px;
  filter: blur(0px);
  color: #ffffff;
  position: relative;
  /* padding: 0 1em; */
  margin: 1em 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  /* top: 76%; */
  height: 100%;
  max-height: 80vh;
  min-height: 80vh;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #4f486a;
  border: 7px solid #8668f2;
  border-radius: 60px;
  filter: blur(0px);
  color: #ffffff;
  position: relative;
  padding: 0 1em;
  margin: 3em 0;
}

.Avatar {
  height: 25em;
  width: 100%;
  background-image: url("https://res.cloudinary.com/dcglxmssd/image/upload/v1679455244/0002_mfcmwp.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
@media screen and (max-width: 300px) {
  .login_card {
  }
}
@media screen and (max-width: 350px) {
  .login_card {
  }
}
.website_btn {
  /* margin-top: 10%; */
  background-color: #ffb281;
  border-radius: 20px;
  padding: auto;
  width: 270px;
  height: 73px;
  transition: all 0.5s;
  cursor: pointer;
  /* margin: auto; */
}
.website_btn {
  /* width: 100%; */
  line-height: 65px;
  /* padding-top: 5%; */
  transition: all 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.website_btn p {
  padding-top: 4.5%;
  /* padding: 3%; */
  /* text-align: center; */
  text-align: center;
  font: normal normal bold 23px/27px Helvetica;
  letter-spacing: 0px;
  color: #ffffff;
  transition: all 0.5s;
  width: 100%;
}
.website_btn:hover p {
  transform: translateX(-5%);
  color: #8668f2;
}
.rocket {
  color: #ffffff;
  margin-left: -10%;
  opacity: 0;
  transition: all 0.5s;
}
.website_btn:hover .rocket {
  transform: translateX(55%);
  opacity: 1;

  margin-left: 5%;
  color: #8668f2;
}
.welcome {
  /* margin-top: 70%; */
  text-align: center;
  font: normal normal bold 65px/78px Helvetica;
  letter-spacing: 0px;
  /* padding-bottom: 5%; */
}
.subtext {
  text-align: center;
  font: normal normal medium 20px/28px Poppins;
  letter-spacing: 2px;
  font-size: 1.4rem;
  padding-top: 0%;
}
.hexagon_inner {
  clip-path: polygon(50% 0, 100% 28%, 100% 68%, 50% 100%, 0 68%, 0 27%);
  height: 900px;
  width: 900px;
  background-color: #29343f;
  position: fixed;
  top: 100px;
  left: 13%;
  border: none;
  z-index: 1;
  filter: drop-shadow(0 0 20px #121212);
  /* border: #1A1C1F;
    border-radius: 10px; */
}
.hexagon_outer {
  clip-path: polygon(50% 0, 100% 28%, 100% 68%, 50% 100%, 0 68%, 0 27%);
  height: 905px;
  width: 905px;
  background-color: #4f486a;
  position: fixed;
  top: 98px;
  left: 12.9%;
  border: none;
  z-index: 1;

  /* border: #1A1C1F; */
  /* border-radius: 20px; */
}
@media (max-width: 2000px) {
  .hexagon_outer {
    display: none;
  }
}
@media (max-width: 1500px) {
  .welcome {
    font-size: 55px;
  }
}

@media (min-width: 1600px) {
  .Main_Login {
    width: 70%;
  }
}
