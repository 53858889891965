.Feedback {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 3em;
}

.Feedback_backticks {
  color: #9cdbfb;
}

.Feedback_Whiten {
  color: white;
}

.Feedback_Text {
  color: grey;
  font-size: 4.5em;
  text-align: left;
  font-family: "Helvetica";
}

.Feedback_First {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Feedback_First,
.Feedback_Second {
  flex: 0.5;
}

.Feedback_Second,
.Feedback_Content {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-end;
  justify-content: flex-start;
}

.Feedback_Second {
  margin: 0 0 15em 1em;
  align-self: flex-end;
}

.Feedback_Content p {
  color: white;
  font-family: "Open Sans";
  font-size: 1.2em;
  text-align: justify;
}

.Feedback_Content button {
  outline: none;
  font-family: "Helvetica";
  padding: 0.5em 1em;
  color: white;
  background-color: #8668f2;
  border-radius: 15px;
  font-size: 1.2em;
  border: none;
}

.Feedback_Content button:hover {
  background-color: rgb(115, 81, 237);
}

@media (max-width: 900px) {
  .Feedback {
    flex-direction: column;
  }
  .Feedback_Content p,
  .Feedback_Text {
    text-align: center;
  }

  .Feedback_Second {
    margin: 1em 0;
  }

  .Feedback_Content {
    align-items: center;
  }
  .Feedback_Content button {
    margin: 0.5em 0;
  }

  .Feedback_First h1 {
    font-size: 2.5em;
  }

  .Feedback_First {
    align-items: flex-end;
    padding: 0.5em 0;
  }
}

@media (max-width: 1500px) and (min-width: 900px) {
  .Feedback_Text {
    font-size: 4em;
  }
}

@media (min-width: 1550px) {
  .Feedback_Text {
    font-size: 5.5em;
  }
}
