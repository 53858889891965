.gallery{
    display: flex;
    justify-content: space-evenly;
    border-radius: 30px;
    flex-wrap: wrap;
}

.img-gallery{
    width: 100%;
    height: 27rem;
    border-radius: 45px;
    object-fit: cover;
    /* height: 350px; */
}

.galleryImage{
    width: inherit;
    height: inherit;
    color: aliceblue;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    
}


.galleryImage:hover{
    color: aliceblue;
}

.cardCustom{
    margin: 10px;
    border-radius: 45px;
    border: none;
    height: auto;
    overflow: hidden;
    background-color: transparent;
}

/* .shadow{
     background-color: #525979;
     border-radius: inherit;
     transition: 200ms;
     object-fit: cover;
} */

.img-gallery:hover{
    /* box-shadow: 10px 10px rgb(66, 68, 70); */
    /* transform: rotate(10deg); */
    transition: 0.3s;
    transform: scale(1.1);
    filter: blur(2px);
    
}

/* .shadow:hover .text{
    opacity: 1;
    z-index: 1;
    
}  */


.card:hover .text{
    opacity: 1;
    z-index: 1;
    
}

.shadow{
    background-color: antiquewhite;
}

.cardCustom:hover .img-gallery{

    /* background-color: black;
    b */
    /* backdrop-filter:1px

}

.img-gallery:hover .text{
    /* filter: blur(2px); */
    opacity: 1;
    color: green;
    z-index: 1;
}

.text{
  position: absolute;
  top: 50%;
  left: 43%;
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(49, 47, 47, 0.438);
  margin-left: 21px;
  transform: translate(-50%, -50%);
  /* background-color: rgba(0, 0, 0, 0.5); */
  color: rgb(255, 255, 255);
  padding: 10px;
  font-size: 26px;
  opacity: 0;
  transition: opacity 0.5s;
}