.MainContainer {
  min-width: 100vw;
  min-height: 100vh;
  padding: 0 0 0 5em;
}

main {
  position: relative;
  height: 100%;
  width: 100%;
}

.Backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background: #00000073;
}

@media screen and (min-width: 0px) and (max-width: 500px) {
  .MainContainer {
    padding: 0em 1em;
  }
}
@media screen and (min-width: 501px) and (max-width: 800px) {
  .MainContainer {
    padding: 5em 2em;
  }
}
