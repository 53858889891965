.About_Main {
  width: 100%;
  margin: 1em 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.About_ImgContainer {
  width: 80%;
  margin: 1em auto;
  overflow: hidden;
  border-radius: 15px;
}

.About_ImgContainer img {
  width: 100%;
}

.About_Main h1 {
  text-align: center;
  color: #ffb281;
  font-size: 5em;
  padding: 1.5em 0 0.5em 0;
  font-weight: 200;
  font-family: "Helvetica";
}

.About_Main p {
  font-family: "Open Sans";
  color: white;
  width: 85%;
  text-align: center;
}

@media (max-width: 500px) {
  .About_Main h1 {
    font-size: 3em;
  }
}
