.Detail {
  width: 100%;
  height: 100%;
  padding: 2em;
}

.Detail_Header {
  margin: 0 0 1em 0;
}

.Detail_Header h1 {
  color: #ffb281;
  font: normal bold 3em Helvetica;
}

.Detail_MainContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  margin: 2em 0;
}

.Detail_ImageContainer {
  width: 55%;
}

.Detail_DetailsContainer {
  width: 45%;
  margin: 0 1em 0 0;
}

.Detail_ImageContainer {
  height: 100%;
  overflow: hidden;
  border-radius: 1em;
}

.Detail_ImageContainer img {
  width: 100%;
  object-fit: contain;
}

.Detail_FirstSection h2 {
  color: white;
}

.Detail_FirstSection p {
  color: grey;
  font-size: 0.9em;
}

.Detail_SecondSection p {
  color: white;
}

.Detail_Item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.Detail_Item p {
  flex: 0.5;
  color: grey;
}

.Detail_Head {
  color: #ffb281 !important;
}

@media (max-width: 800px) {
  .Detail_MainContainer {
    flex-direction: column-reverse;
  }

  .Detail {
    padding: 5em 1em 0 1em;
  }

  .Detail_DetailsContainer,
  .Detail_ImageContainer {
    width: 100% !important;
  }

  .Detail_ImageContainer {
    margin: 1em 0;
  }
}
