@font-face {
  font-family: Helvetica;
  src: url("../../fonts/Helvetica\ Neue\ Medium\ Extended.otf");
}

/* #contact_section{
  padding-top: 50px;
  padding-bottom: 100px;
} */

.FormOuterContainer {
  height: fit-content !important;
  width: 100%;
}

.FormContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  width: 90%;
  margin: 0 auto;
}

#left-container {
  padding-top: 50px;
  padding-bottom: 100px;
}

#right-container {
  width: 50%;
}

#contact_head-1 {
  font-family: Helvetica;
  font-size: 85px;
  color: white;
}

#contact_head-2 {
  color: #ffb281;
}

#contact_description {
  font-family: Helvetica;
  font-size: 20px;
  color: white;
}

.sub-head {
  font-family: Helvatica;
  color: white;
  font-size: 17px;
  font-weight: lighter;
}

#fname,
#lname,
#mail,
#message {
  background-color: #4c565d;
  color: white;
}

#send_msg_btn {
  background-color: #8668f2;
  color: white;
  font-family: Helvetica;
  font-size: 18px;
  border-radius: 15px;
  padding: 15px;
}

.outer_info_card {
  border: 7px solid #8668f2 !important;
  border-radius: 60px !important;
  opacity: 1;
  max-width: 550px !important;
  background-color: #d295c2 !important;
}

#head-con {
  line-height: 1;
  color: white;
  padding: 10px;
  padding-top: 45px;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  font-family: Helvetica;
}

.con-1 {
  margin: 18px;
  margin-top: 30px;
  padding: 17px !important;
  color: white;
  border: 7px solid #8668f2 !important;
  border-radius: 40px !important;
  opacity: 1;
  filter: blur(0px);
  display: flex !important;
  flex-direction: row;
  background-color: #4c565d !important;
}

.con-2 {
  margin: 28px;
  color: white;
  padding: 15px;
  font-size: 24px;
  /* text-align: center; */
  border: 7px solid #8668f2;
  border-radius: 40px;
  opacity: 1;
  filter: blur(0px);
  justify-content: space-evenly;
  display: flex;
  background-color: #4c565d;
}

.left-content {
  font-size: 2em;
  color: white;
  height: 100%;
  padding-right: 10px;
}

.right-content {
  padding: 10px;
}

.right-content h3 {
  font-size: 25px;
  font-family: Helvetica;
}

.right-content p {
  font-size: 15px;
  color: #8790a2;
}

.input_box_contact {
  border-radius: 12px;
}

.con-2 a {
  color: white;
  text-decoration: none;
}

.con-2 a:hover {
  color: #8c89c8;
}

.side-by-side {
  display: flex;
}

.labelText {
  font-family: "Helvetica";
}

/* Desktops & laptops: */
@media (max-width: 900px) {
  .FormContainer {
    flex-direction: column;
  }
}

/* Tablets & iPads: */
@media (min-width: 769px) and (max-width: 1023px) {
  #contact_head-1 {
    font-size: 70px;
  }
}

/* Mobile phones: */
@media (max-width: 768px) {
  #contact_head-1 {
    font-size: 70px;
  }
}

@media (max-width: 468px) {
  #contact_head-1 {
    font-size: 50px;
  }
  .left-content {
    font-size: 55px;
    padding-top: 60px;
  }
  .right-content h3 {
    font-size: 19px;
  }
  .right-content p {
    font-size: 12px;
  }
}

@media (min-width: 100px) and (max-width: 350px) {
  #right-container {
    display: none;
    visibility: hidden;
  }
}
