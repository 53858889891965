@font-face {
  font-family: Helvetica;
  src: url("../../fonts/Helvetica\ Neue\ Medium\ Extended.otf");
}

.recent_heading {
  text-align: center;
  color: #ffb281;
  font-family: Helvetica;
  font-size: 5em;
  padding-top: 2em;
  padding-bottom: 2em;
  font-weight: 200;
}

.StaffPosition {
  color: #a8a7a7;
}

#inspiration {
  padding-top: 50px;
  padding-bottom: 100px;
}

#inspiration-1,
#inspiration-2 {
  padding: 25px;
}

#img1,
#img2 {
  width: 350px;
  height: 350px;
  border-radius: 20px;
  padding: 10px;
}

.StaffName {
  color: white;
  font-family: "Open Sans";
  margin: 1em 0;
  text-align: center;
  font-weight: bold;
}

/* @media (max-width:350px){
    .recent_heading{
        font-size: 65px;
    }
} */

/* Desktops & laptops: */
@media (min-width: 1024px) {
}

/* Tablets & iPads: */
@media (min-width: 769px) and (max-width: 1023px) {
  .recent_heading {
    font-size: 70px;
    text-align: center;
  }
}

/* Mobile phones: */
@media (max-width: 768px) {
  .recent_heading {
    font-size: 70px;
    text-align: center;
  }
}

@media (max-width: 468px) {
  .recent_heading {
    font-size: 50px;
  }
}
