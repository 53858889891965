@font-face {
  font-family: Helvetica;
  src: url("../../fonts/Helvetica\ Neue\ Medium\ Extended.otf");
}

.container,
.cardContainer {
  width: 100%;
  height: fit-content;
}

.card-Homebody {
  padding: 0.5em;
}

.recent_heading {
  text-align: center;
  color: #ffb281;
  font-family: Helvetica;
  font-size: 5em;
  padding-top: 2em;
  padding-bottom: 2em;
  font-weight: 200;
}

.card-text {
  overflow: hidden;
  max-width: 38ch;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: grey !important;
}

.card-text span {
  color: white !important;
}

.cardContainer {
  width: 100%;
  height: 100%;
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.card-deck {
  height: min-content;
  width: 100%;
  background-color: #242632;
  overflow: hidden;
  border-radius: 0.8em;
  min-height: 26.5em;
}

.cardInside {
  background-color: #242632;
}

.csImage {
  min-height: 15em;
  max-height: 15em;
}
/* 
.card-deck {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
} */

/* #cd-1,
#cd-2,
#cd-3 {
  background-color: #242632;
  color: #8790a2; */
/* width: 400px; */
/* border-radius: 40px;
  border-bottom: solid 20px #b9b6ff;
} */

/* #btn {
  text-align: center;
  padding: 10px;
} */

/* .btn-success,
.btn-success:hover,
.btn-success:active,
.btn-success:visited {
  background-color: #242632;
} */

/* Desktops & laptops: */
/* @media (min-width: 1024px) {
} */

/* Tablets & iPads: */
/* @media (min-width: 769px) and (max-width: 1023px) {
  .recent_heading {
    font-size: 70px;
    text-align: center;
  }
} */

/* Mobile phones: */
/* @media (max-width: 768px) {
  .recent_heading {
    font-size: 70px;
    text-align: center;
  }
}

@media (max-width: 468px) {
  .recent_heading {
    font-size: 50px;
  }
} */
