.ContactUs {
  width: 100%;
  height: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ContactUs_Heading h1 {
  text-align: center;
  color: #ffb281;
  font-size: 5em;
  padding: 1.5em 0 0.5em 0;
  font-weight: 200;
  font-family: "Helvetica";
}

.ContactUs_content {
  width: 80%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  margin: 1em auto;
}

.ContactUs_Single {
  flex: 0.5;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: white;
}

.ContactUs_Single svg {
  font-size: 1.5em;
}

.ContactUs_Single p,
.ContactUs_Single a {
  margin: 0 1em !important;
  color: white !important;
}

.ContactUs_Address {
  font-size: 2.5em !important;
  margin: -0.2em 0;
}

@media (max-width: 1000px) {
  .ContactUs_Heading h1 {
    font-size: 3.5em;
  }
  .ContactUs_content {
    flex-direction: column;
  }

  .ContactUs_Single {
    margin: 1em 0;
  }
}
