@font-face {
  font-family: Helvetica;
  src: url("../../fonts/Helvetica\ Neue\ Medium\ Extended.otf");
}

.nav_elements {
  list-style: none;
  text-decoration: none;
  gap: 5em;
  font-size: 25px;
  font-weight: 400;
  font: normal normal 20px/40px Helvetica;
  color: #000000;
  line-height: 2em;
}

.memberNav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: #8668f2;
  border-radius: 10px;
  width: 80%;
  text-align: center;
  margin: 2em auto;
}
.nav_elements {
  cursor: pointer;
}
.nav_elements :hover {
  color: #ffffff;
}
.Board {
  border-bottom: 5px solid #ffffff;
  color: #4f486a;
}

@media (max-width: 985px) {
  .nav_elements {
    font-size: 1em;
  }
  .memberNav {
    flex-direction: column;
    margin: 5em auto;
  }
}
