.App {
  min-width: 100vw;
  min-height: 100vh;
}
.btn.btn-primary.card-btn {
  margin: 1.25em 1.5em;
   /* Adjust the value to your desired margin */
}

.dropdown,
#dropdown-basic {
  color: #fff !important;
  background-color: #8668f2 !important;
  border-color: #8668f2 !important;
  box-shadow: 0 0 0 0.25rem rgb(134 104 242);
}

