@font-face {
  font-family: Helvetica;
  src: url("../../fonts/Helvetica\ Neue\ Medium\ Extended.otf");
}
.child_container {
  width: 1338px;
  height: 532px;
  border: 7px solid #8668f2;
  border-radius: 110px;
  opacity: 1;
  padding: 0;
  margin: 0;
  background-color: #4f486a;
}
.title {
  width: 48%;
  height: 146px;
  text-align: left;
  font: normal normal bold 64px/102px Helvetica;
  line-height: 69px;
  margin-left: 8%;
}
.sub_title {
  height: 25px;
  font: normal normal medium 15px/21px Poppins;
  letter-spacing: 1.5px;
  margin-left: 8%;
  margin-top: 29px;
  margin-bottom: 64px;
}
.search {
  width: 47%;
  height: 61px;
  border: 1px solid #ffffff;
  border-radius: 20px;
  margin-left: 8%;
  background-color: rgba(255, 255, 255, 0.1);
  font: normal normal normal 18px/30px Poppins;
  color: white;
}
.Inputele {
  background-color: transparent;
  border: none;
  width: 100%;
  height: 61px;
  color: white;
  font: normal normal normal 18px/30px Poppins;
  border-radius: 20px;
}

.btn-success,
.dropdown {
  color: #fff !important;
  background-color: #8668f2 !important;
  border-color: #8668f2 !important;
}

/* .btn-success:focus,
.btn-success:hover {
  color: #fff !important;
  background-color: #8668f2 !important;
  border-color: #8668f2 !important;
}

.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgb(134 104 242);
}

.show > .btn-success.dropdown-toggle {
  color: #fff !important;
  background-color: #8668f2 !important;
  border-color: #8668f2 !important; */
/* } */

@media (min-width: 218px) and (max-width: 468px) {
  .child_container {
    border-radius: 70px;
  }
  .title {
    line-height: 60px;
    font-size: 40px;
    width: 100%;
    height: auto;
  }
  .sub_title {
    width: 100%;
    height: auto;
  }
  input {
    width: 100%;
  }
  .search {
    width: 84%;
  }
  .container {
    margin: auto;
  }
}

@media (min-width: 467px) and (max-width: 768px) {
  /* * {
    overflow-x: hidden;
  } */
  .child_container {
    width: 800px;
    border-radius: 70px;
  }
  .title {
    line-height: 60px;
    font-size: 50px;
    width: 400px;
    height: auto;
  }
  .sub_title {
    width: 300px;
    height: auto;
  }
  input {
    width: 450px;
  }
  .search {
    width: 83%;
    /* width: 350px; */
  }
  .Inputele {
    width: 100%;
  }
}

@media (min-width: 769px) and (max-width: 918px) {
  /* * {
    overflow-x: hidden;
  } */
  .child_container {
    width: 800px;
    border-radius: 90px;
  }
  .title {
    line-height: 60px;
    font-size: 50px;
    width: 400px;
    height: auto;
  }
  .sub_title {
    width: 400px;
    height: auto;
  }
  input {
    width: 400px;
  }
  .search {
    width: 400px;
  }
}

@media (min-width: 918px) and (max-width: 1026px) {
  /* * {
    overflow-x: hidden;
  } */
  .child_container {
    width: 900px;
    border-radius: 90px;
  }
  .title {
    line-height: 60px;
    font-size: 50px;
    width: 600px;
    height: auto;
  }
  .sub_title {
    width: 400px;
    height: auto;
  }
  input {
    width: 400px;
  }
  .search {
    width: 400px;
  }
  .note_list {
    grid-template-columns: repeat(auto-fit, minmax(418px, 2fr));
    margin-left: 0px;
  }
}

@media (min-width: 1024px) and (max-width: 1375px) {
  /* * {
    overflow-x: hidden;
  } */

  .title {
    width: 700px;
    height: auto;
  }
}
